
/**
 * Desktop view for the table whit al the devices in the system
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import { Component, Mixins } from "vue-property-decorator";
import { InventoryTableMix } from "@/mixins/Inventory/InventoryTableMixin";
import { BTable } from "bootstrap-vue";
import { Device } from "@/interface/DeviceInterface";

@Component({
  name: "InventoryTableMobile"
})
export default class InventoryTableMobile extends Mixins(InventoryTableMix) {
  public $refs!: {
    inventoryTable: BTable;
  };

  /*----------  local Data  ----------*/
  localSelected: Array<Device> = this.selected;

  /*----------  Methods  ----------*/
  handleSelected(item: Device) {
    //@ts-ignore
    if (item.selected) {
      this.localSelected.push(item);
    } else {
      this.localSelected = this.selected.filter((device: Device) => device.id !== item.id);
    }

    this.$emit("rowSelected", this.localSelected);
  }
  get mobileFiltered() {
    if (this.filter) {
      let items = this.filteredItems
        .map((item) => {
          let stringItem: string = JSON.stringify(item).toLowerCase();
          return stringItem.includes(this.filter.toLowerCase()) ? item : null;
        })
        .filter((item) => item !== null);
      return items;
    } else {
      return this.filteredItems;
    }
  }
}
