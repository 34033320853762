<template>
  <b-row align-h="between" align-v="center">
    <b-col cols="12" :sm="headerColsSm" :md="headerColsMd" :lg="headerCols" order="1" order-md="1">
      <h4 class="mb-2 mb-md-0 float-sm-none custom-font-header">{{ title }}</h4>
    </b-col>
    <slot name="extra-items"></slot>
    <b-col
      cols="12"
      :sm="headerColsBtnSm"
      :md="headerColsBtnMd"
      :lg="headerColsBtn"
      v-if="btnContent"
      order="2"
      order-md="3"
      class="d-flex d-md-block"
    >
      <slot name="extra-buttons"></slot>
      <b-button
        class="float-lg-right mr-md-2 mt-2 mt-sm-0 flex-fill"
        v-if="canViewAction"
        variant="success"
        data-test="new-item"
        @click="$emit('openModal')"
       
      >
        <i v-if="btnIcon" :class="btnIcon"></i>
        <span class="d-none d-md-inline-block">
          {{ btnContent }}
        </span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "TableHeader",
  props: {
    title: {
      type: String
    },
    btnContent: {
      default: "",
      required: false
    },
    btnTooltip: {
      default: "",
      required: false
    },
    btnIcon: {
      default: "",
      required: false
    },
    headerCols: {
      default: "4"
    },
    headerColsBtn: {
      default: "4"
    },
    headerColsSm: {
      default: "4"
    },
    headerColsBtnSm: {
      default: "6"
    },
    headerColsMd: {
      default: "3"
    },
    headerColsBtnMd: {
      default: "4"
    },
    canViewAction: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style scoped>
.custom-font-header {
  font-size: 18px;
  font-weight: 700;
}
</style>
