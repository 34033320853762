
/**
 * Desktop view for the table whit al the devices in the system
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import { Component, Mixins, Prop } from "vue-property-decorator";
import { InventoryTableMix } from "@/mixins/Inventory/InventoryTableMixin";
import { BTable } from "bootstrap-vue";

@Component({
  name: "InventoryTableDesktop"
})
export default class InventoryTableDesktop extends Mixins(InventoryTableMix) {
  public $refs!: {
    inventoryTable: BTable;
  };

  /*----------  Props  ----------*/
  @Prop({ required: true }) filterIsOpen!: boolean;

  handleDeviceTypeTranslation(name: string) {
    return this.$t(`inventory.createNewDevice.device_types.${name}`);
  }
}
