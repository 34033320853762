<script>
/**
 * Create a new device and the update and existing device
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [ ] Update the typescript.
 */

import { mapActions, mapGetters } from "vuex";
import { required, minLength, alphaNum } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },
  props: {
    device: {
      default: function () {
        return {
          serialNumber: null,
          routerSerialNumber: null,
          emnifyIccId: null,
          type: null
        };
      },
      type: Object
    },
    isUpdate: {
      default: false,
      type: Boolean
    },
    isDisabledSerialNumber: {
      default: false,
      type: Boolean
    }
  },
  data: function () {
    return {
      state: this.device.state,
      status: null,
      typeSelected: this.device.type,
      newDevice: this.device,
      localSelectedType: this.device.type
    };
  },
  validations: {
    newDevice: {
      serialNumber: {
        alphaNum,
        required,
        minLength: minLength(1)
      }
    },
    typeSelected: {
      required
    }
  },
  created() {
    this.getAllTypesFromBackEnd();
    this.resetErrors(false);
    this.getAllStatesFromBackEnd();
    this.localSelectedType = this.device.type.name;
  },
  computed: {
    ...mapGetters(["getAllDeviceTypes", "getErrorFromInventoryList", "getAllDeviceStates"]),
    removeSpaces() {
      var regex = /[^a-zA-Z0-9]+/;
      return !regex.test(this.newDevice.serialNumber);
    },
    isFormValid() {
      if (this.isUpdate) {
        return this.$v.$invalid;
      } else {
        return this.$v.$invalid;
      }
    }
  },
  methods: {
    ...mapActions([
      "createNewDevice",
      "getAllTypesFromBackEnd",
      "getAllStatesFromBackEnd",
      "resetErrors",
      "updateDevice"
    ]),
    resetDeviceValue() {
      if (this.isUpdate) {
        this.$bvModal.hide("updateDevice");
      } else {
        this.$bvModal.hide("newDevice");
      }
      this.newDevice = {
        serialNumber: null,
        deviceType: null
      };
      this.typeSelected = null;
    },
    async handleCreateDevice() {
      try {
        if (this.$v.$invalid) {
          this.status = {
            class: "Danger",
            msg: "All inputs are required"
          };
        } else {
          this.status = null;
          this.newDevice.typeId = this.typeSelected.id;
          await this.createNewDevice(this.newDevice).then((res) => {
            if (res.status === 202) {
              this.$emit("new-device-status", "newDevice", {
                class: "success",
                msg: this.$t("inventory.msgStatus.success")
              });
            } else {
              this.status = {
                class: "danger",
                msg: this.$t("inventory.msgStatus.error")
              };
            }
          });
        }
      } catch (error) {
        this.status = this.getErrorFromInventoryList;
        this.$emit("new-device-status", false);
      }
    },
    async handleUpateDevice() {
      if (this.state === null) {
        this.status = {
          class: "danger",
          msg: this.$t("inventory.msgStatus.error")
        };
        return;
      }
      this.newDevice.state = this.state;
      this.newDevice.type = this.typeSelected;
      this.updateDevice(this.newDevice).then((res) => {
        if (res.status === 202) {
          this.$emit("new-device-status", "updateDevice", {
            class: "success",
            msg: this.$t("inventory.msgStatus.updateDeviceSuccess")
          });
        } else {
          this.status = this.getErrorFromInventoryList;
          this.$emit("new-device-status", false);
        }
      });
    },
    handleCreate() {
      this.status = null;
      if (this.isUpdate) {
        this.handleUpateDevice();
      } else {
        this.handleCreateDevice();
      }
    },
    changeLocalSelectedTypeToHideOrShowRouterSerialNumber(deviceType) {
      this.localSelectedType = deviceType.name;
    },

    // TODO: Import from another File
    handleTranslation() {

      let res1 = this.getAllDeviceTypes;
      let parseObj = JSON.parse(JSON.stringify(res1));

      parseObj.map( (value, key) => {
        parseObj[key]['name'] = this.translateDeviceTypes(parseObj[key]['name']);
      });

      return parseObj;
    },

    translateDeviceTypes(name) {
      return this.$t(`inventory.createNewDevice.device_types.${name}`);
    }

  }

 
};
</script>

<template>
  <b-form @submit.prevent="handleCreate" @reset.prevent="resetDeviceValue">
    <b-alert data-test="status-msg" v-if="status" show :variant="status.class" dismissible>{{ status.msg }}</b-alert>
    <b-row class="my-1">
      <b-col sm="3">
        <label for="device-number">
          {{ $t("inventory.createNewDevice.serialNumberLabel") }}
          <span class="text-danger">*</span>
        </label>
      </b-col>
      <b-col sm="9">
        <b-form-input
          data-test="device-serial-number"
          type="number"
          v-model.trim="$v.newDevice.serialNumber.$model"
          required
          :disabled=this.isDisabledSerialNumber
        ></b-form-input>
        <b-col sm="10">
          <b-form-invalid-feedback :state="$v.newDevice.serialNumber.alphaNum" id="input-live-feedback">
            {{ $t("inventory.createNewDevice.serialNumberError") }}
          </b-form-invalid-feedback>
        </b-col>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col sm="3">
        <label for="device-number">
          {{ $t("inventory.createNewDevice.deviceTypeLabel") }}
          <span class="text-danger">*</span>
        </label>
      </b-col>
      <b-col sm="9">
        <Multiselect
          v-model.trim="$v.typeSelected.$model"
          :options="handleTranslation()"
          track-by="name"
          label="name"
          data-test="device-type"
          :show-labels="false"
          :placeholder="$t('inventory.createNewDevice.deviceTypeNull')"
          class="mb-1"
          @select="changeLocalSelectedTypeToHideOrShowRouterSerialNumber"
        />
      </b-col>
    </b-row>
    <b-row class="my-1" v-if="isUpdate">
      <b-col sm="3">
        <label for="device-status">
          {{ $t("inventory.createNewDevice.statusLabel") }}
          <span class="text-danger">*</span>
        </label>
      </b-col>
      <b-col sm="9">
        <Multiselect
          data-test="device-status"
          v-model="state"
          :options="getAllDeviceStates"
          :show-labels="false"
          :placeholder="$t('inventory.createNewDevice.deviceTypeNull')"
          class="mb-1"
        />
      </b-col>
    </b-row>
    <b-row class="mb-1" v-if="this.localSelectedType !== 'battery'">
      <b-col sm="3">
        <label for="device-number">{{ $t("inventory.createNewDevice.routerSerialNumberLabel") }}</label>
      </b-col>
      <b-col sm="9">
        <b-form-input
          data-test="device-router-serial-number"
          v-model.trim="device.routerSerialNumber"
          :placeholder="$t('inventory.createNewDevice.routerSerialNumberPlaceHolder')"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col sm="3">
        <label for="device-number">{{ $t("inventory.createNewDevice.emnifyiccidLabel") }}</label>
      </b-col>
      <b-col sm="9">
        <b-form-input
          data-test="device-icc-id"
          v-model.trim="device.emnifyIccId"
          :placeholder="$t('inventory.createNewDevice.emnifyIccIdPlaceHolder')"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="12" lg="4" class="mr-auto text-muted mb-1 mb-lg-0">
        <span class="text-danger pr-2">*</span>
        {{ $t("users.newUserForm.required") }}
      </b-col>
      <b-col cols="12" lg="3">
        <b-button
          block
          v-b-tooltip.hover
          :title="$t('inventory.tooltips.newDevice.reset')"
          type="reset"
          class="mb-1 mb-lg-0 custom-btn-lg"
          variant="light"
        >
          {{ $t("inventory.createNewDevice.btnResetText") }}
        </b-button>
      </b-col>
      <b-col cols="12" lg="3">
        <span
          class="d-inline-block"
          tabindex="0"
          v-b-tooltip.top
          :title="
            isUpdate ? $t('inventory.tooltips.deviceUpdate.updateBtnText') : $t('inventory.tooltips.newDevice.create')
          "
        >
          <b-button
            class="custom-btn-lg"
            v-b-tooltip.hover
            :title="
              isUpdate ? $t('inventory.tooltips.deviceUpdate.updateBtnText') : $t('inventory.tooltips.newDevice.create')
            "
            block
            type="submit"
            :disabled="isUpdate ? (!state ? true : false && isFormValid) : isFormValid"
            variant="success"
          >
            {{ isUpdate ? $t("inventory.deviceUpdate.updateBtnText") : $t("inventory.createNewDevice.btnCreateText") }}
          </b-button>
        </span>
      </b-col>
    </b-row>
  </b-form>
</template>
<style></style>
