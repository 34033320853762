import { ActionMessage } from "./../../model/Messages/statusMessage";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import { mapActions, mapGetters } from "vuex";
import { Device } from "@/interface/DeviceInterface";
import { AxiosResponse } from "axios";

@Component({
  components: {
    Multiselect
  },
  computed: {
    ...mapGetters(["getAllDevices", "isUserAdmin"])
  },
  methods: {
    ...mapActions([
      "changeDeviceStatus",
      "getAllDevicesFromBackEnd",
      "archiveDevice",
      "updateDevice",
      "updateGpsPositionDevice"
    ])
  }
})
export class InventoryTableMix extends Vue {
  @Prop({ required: true }) fields!: Array<object>;
  @Prop({ required: true }) selected!: Array<Device>;
  @Prop({ required: true }) filter!: any;
  @Prop({ required: true }) perPage!: number;
  @Prop({ required: true }) currentPage!: number;
  @Prop({ required: true }) filterState!: string;
  @Prop({ required: true }) tableIsBusy!: boolean;
  @Prop({ required: false, default: false }) allSelected!: boolean;
  @Prop({ required: true, default: () => [] }) filterOn!: any;

  private getAllDevices!: Array<object>;
  private getAllDevicesFromBackEnd!: Function;
  private archiveDevice!: Function;
  private updateDevice!: Function;
  updateGpsPositionDevice!: Function;

  private readonly isUserAdmin!: boolean;

  // Emit from this component
  deviceStatus: boolean | object = false;

  created() {
    this.getAllDevicesFromBackEnd();
  }
  onFiltered(filteredItems: any) {
    this.currentPage = 1;
  }
  onRowSelected(items: Array<Device>) {
    this.$emit("rowSelected", items);
  }

  /*----------  Filtering in the table   ----------*/
  get filteredItems() {
    if (this.filterState) {
      const items = this.getAllDevices
        .map((item) => {
          const stringItem: string = JSON.stringify(item).toLowerCase();
          return stringItem.includes(this.filterState.toLowerCase()) ? item : null;
        })
        .filter((item) => item !== null);
      return items;
    } else {
      return this.getAllDevices;
    }
  }

  /*----------  Helper functions  ----------*/
  confirmModal(msg: string, action: Function) {
    this.$bvModal
      .msgBoxConfirm(msg, {
        title: (this as any).$t("inventory.deviceActions.archiveDevice.modalTitle"),
        size: "md",
        buttonSize: "md",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0 bg-light",
        footerClass: "p-2 border-top-0",
        centered: true,
        okTitle: (this as any).$t("utils.alertBox.ok"),
        cancelVariant: "light",
        cancelTitle: (this as any).$t("utils.alertBox.cancel")
      })
      .then((value: boolean) => {
        if (value) {
          action();
        }
      });
  }
  /*----------  User Actions  ----------*/

  viewItemDetails(id: number) {
    const userLink = `inventory/${id.toString()}`;
    this.$router.push({ path: userLink });
  }

  doubleCheckArchiveDevice(id: string) {
    this.$emit("updateStatus", false);
    this.confirmModal(
      (this as any).$t("inventory.deviceActions.archiveDevice.modalText", {
        deviceId: id
      }),
      () => {
        this.$emit("archive-device", id);
      }
    );
  }

  handleChangeDeviceStatus(device: Device, newStatus: string) {
    this.$emit("updateStatus", false);
    this.confirmModal(
      (this as any).$t("inventory.deviceActions.deviceStatusChange.modalText", {
        status: newStatus
      }),
      () => {
        this.tableIsBusy = true;
        device.state = newStatus;
        this.updateDevice(device).then((res: AxiosResponse) => {
          if (res.status === 202) {
            this.$emit("updateStatus", {
              class: "success",
              msg: this.$t("inventory.msgStatus.statusChangeSuccess", {
                newStatus: newStatus
              })
            });

            this.getAllDevicesFromBackEnd();
            this.tableIsBusy = false;
          } else {
            this.tableIsBusy = false;
            this.$emit("updateStatus", {
              class: "danger",
              msg: res
            });
          }
        });
      }
    );
  }

  doubleCheckRebootRouter(serialNumber: string) {
    this.confirmModal((this as any).$t("inventory.deviceActions.rebootRouter"), () => {
      this.$emit("reboot-router", serialNumber);
    });
  }
  checkGPSResponseAvailability(response: any) {
    return (
      (response.data.latitude !== 0 || response.data.longitude !== 0) &&
      (response.data.gpsSmsStatusDescription === "DELIVERY ATTEMPT PENDING" ||
        response.data.gpsSmsStatusDescription === "DELIVERED")
    );
  }

  callGps(serialNumber: String) {
    this.$emit("updateStatus", null);
    this.confirmModal(
      (this as any).$t("project.singleProject.battery.modalAddBatteryToProject.confirmCallGps", {
        id: serialNumber
      }),
      async () => {
        try {
          const updateBatteryInProjectStatus = await this.updateGpsPositionDevice(serialNumber);
          if (
            updateBatteryInProjectStatus.status === 202 &&
            this.checkGPSResponseAvailability(updateBatteryInProjectStatus)) {
            if(updateBatteryInProjectStatus)

            this.$emit("updateStatus", {
              class: "success",
              msg:
                (this as any).$t(`project.singleProject.battery.modalAddBatteryToProject.callGPSSuccess`) +
                "(" +
                (this as any).$t("inventory.tableLabels.serialNumber") +
                ": " +
                updateBatteryInProjectStatus.data.serialNumber +
                ")"
            });
          } else {
            this.$emit("updateStatus", {
              class: "danger",
              msg:
                (this as any).$t(`project.singleProject.battery.modalAddBatteryToProject.callGPSFaild`) +
                " (" +
                (this as any).$t("inventory.tableLabels.serialNumber") +
                ": " +
                updateBatteryInProjectStatus.data.serialNumber +
                " / SMS status: " +
                updateBatteryInProjectStatus.data.gpsSmsStatusDescription +
                " ) "
            });
          }
        } catch (error) {
          this.$emit("updateStatus", {
            class: "danger",
            msg: error
          });
        }
      }
    );
  }
}
