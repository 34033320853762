
/**
 * Filtering and search for the device list.
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Multiselect from "vue-multiselect";
import i18n from "@/i18n";

interface DeviceType {
  name: string;
}

@Component({
  name: "InventorySearchandFiltering",
  components: {
    Multiselect
  },
  computed: {
    ...mapGetters(["getAllDeviceTypes", "getAllDeviceStates"])
  },
  methods: {
    ...mapMutations(["FilterByType"]),
    ...mapActions(["SearchDevices", "getAllDevicesFromBackEnd", "getAllStatesFromBackEnd", "getAllTypesFromBackEnd"])
  }
})
export default class InventorySearchandFiltering extends Vue {
  @Prop({ required: true }) typeToFilter!: DeviceType | null;
  readonly getAllDeviceStates!: Array<object>;
  readonly getAllDeviceTypes!: Array<object>;

  private SearchDevices!: Function;
  private getAllDevicesFromBackEnd!: Function;
  private getAllStatesFromBackEnd!: Function;
  private getAllTypesFromBackEnd!: Function;
  private FilterByType!: Function;
  // Local data
  // Local data
  searchFor: string = "";
  searchInTableFor: string = "";
  isLoadingForResults: boolean = false;
  deviceStateFilter: null | string = null;
  deviceTypeFilter: DeviceType | null = null;
  deviceTableSearchInputFilter: null | string = null;
  filterOn: Array<object> = [];
  filterIsOpen: boolean = false;

  created() {
    this.getAllStatesFromBackEnd();
    this.getAllTypesFromBackEnd();
  }
  @Watch("typeToFilter", { immediate: true })
  handleTypeFilterChange(type: any) {
    this.deviceTypeFilter = type;
  }
  async handleSearchEvent() {
    this.isLoadingForResults = true;
    // Send a request tot the back end with the search term
    let searchRequest = await this.SearchDevices(this.searchFor);

    if (searchRequest.status === 200) {
      // show a meassage if serach is succefully made
      console.log(searchRequest);
    } else {
      // Handle error of empy search
    }
    this.isLoadingForResults = false;
  }

  async handleClearSearch() {
    this.searchFor = "";
    await this.getAllDevicesFromBackEnd();
  }
  handleChangeFilterByState() {
    this.deviceTypeFilter = null;
    this.$emit("handleFilterActions", this.deviceStateFilter);
  }
  handleChangeFilterByType() {

    let parseObj = JSON.parse(JSON.stringify(this.deviceTypeFilter));

    if(parseObj) {

      // Only if the language is german
      if(i18n.locale == 'de') {

        /**
         * Translate the German word back to english again to filter correctly
         * The data from backend is always on english. The German words will never find in the data
         * Thats the Reason why we translate only for the filter back in english again
         */
        // 
        parseObj.name = this.translateDeviceTypesUndo(parseObj.name);
        this.deviceTypeFilter = parseObj;

      }
      
      this.FilterByType(this.deviceTypeFilter);
      this.$emit("filterTypeChange", this.deviceTypeFilter);

      if(i18n.locale == 'de') {

        // After Filter turn to german again, Otherwise the english version will write on the multiselect-filter
        parseObj.name = this.translateDeviceTypes(parseObj.name);
        this.deviceTypeFilter = parseObj;

      } 

    }

    // If the choice is empty array - reset the filter
    else  {

      // Reset the Filter
      this.getAllDevicesFromBackEnd();
    }

  }
  handleChangeFilterByTableSearchInput() {
    this.$emit("handleFilterActions", this.searchInTableFor);
  }
  async handleResetFiltering() {
    this.deviceStateFilter = null;
    this.deviceTypeFilter = null;
    this.searchInTableFor = "";
    this.filterOn = [];
    this.handleChangeInFilterOptions();
    await this.getAllDevicesFromBackEnd();

    this.$emit("resetFilter");
  }
  handleChangeInFilterOptions() {
    this.$emit("filterOnOptionsChange", this.filterOn);
  }

  handleTranslation() {

    let res1 = this.getAllDeviceTypes;
    let parseObj = JSON.parse(JSON.stringify(res1));

    parseObj.map( (value: string, key: string) => {
      parseObj[key]['name'] = this.translateDeviceTypes(parseObj[key]['name']);
    });

    return parseObj;
  
  }
  translateDeviceTypes(name: string) {
    return this.$t(`inventory.createNewDevice.device_types.${name}`);
  }
  translateDeviceTypesUndo(name: string) {
    return this.$t(`inventory.createNewDevice.device_types_undo.${name}`);
  }
}
