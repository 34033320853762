
/**
 * Device list vie all the devices that we  get from the back end is here ,
 * Filtering fro the client side an the change devices status for more than one is posible here.
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import { Vue, Component } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";

import TableHeader from "@/components/tableHeader.vue";
import newDevice from "@/views/inventory/newDevice.vue";
import { Device } from "@/interface/DeviceInterface";

import { BTable } from "bootstrap-vue";
import Multiselect from "vue-multiselect";
import InventorySearchandFiltering from "@/components/Inventory/InventorySearchandFiltering.vue";
import InventoryTableDesktop from "@/components/Inventory/InventoryTableDesktop.vue";
import InventoryTableMobile from "@/components/Inventory/InventoryTableMobile.vue";
import UserAccess from "@/model/User/UserAccess";
import { ActionMessage } from "@/model/Messages/statusMessage";
import i18n from "@/i18n";

declare module "vue/types/vue" {
  // eslint-disable-next-line no-unused-vars
  interface Vue {
    $mq: "sm" | "md" | "lg" | "xl"; // Or keyof BreakpointRecord
  }
}

interface RespStatus {
  msg: string;
  class: string;
}

@Component({
  name: "InventoryList",
  components: {
    newDevice,
    TableHeader,
    Multiselect,
    InventorySearchandFiltering,
    InventoryTableDesktop,
    InventoryTableMobile
  },
  computed: {
    ...mapGetters(["getAllDevices", "isUserAdmin", "getAllDeviceStates", "getUserAccess"])
  },
  methods: {
    ...mapActions(["getAllDevicesFromBackEnd", "archiveDevice", "rebootRouter", "updateDevice"])
  }
})
export default class InventoryList extends Vue {
  private getAllDevices!: Array<Device>;
  private getAllDeviceStates!: Array<string>;
  readonly isUserAdmin!: boolean;
  private readonly getUserAccess!: UserAccess;

  private getAllDevicesFromBackEnd!: Function;
  private updateDevice!: Function;
  private archiveDevice!: Function;
  private rebootRouter!: Function;

  public $refs!: {
    desktopTable: BTable;
  };

  fields: Array<string | any> = [
    {
      key: "selected",
      sortable: false,
      label: this.translateTableLabel("selected")
    },
    {
      key: "id",
      sortable: false,
      label: this.translateTableLabel("id")
    },
    {
      key: "articleNumber",
      sortable: false,
      label: this.translateTableLabel("articleNumber")
    },

    {
      key: "serialNumber",
      sortable: true,
      label: this.translateTableLabel("serialNumber")
    },
    {
      key: "type",
      sortable: true,
      label: this.translateTableLabel("type")
    },
    {
      key: "state",
      sortable: true,
      label: this.translateTableLabel("state")
    },
    {
      key: "routerSerialNumber",
      sortable: true,
      label: this.translateTableLabel("routerSerialNumber")
    },
    {
      key: "emnifyIccId",
      sortable: true,
      label: this.translateTableLabel("emnifyIccId")
    },
    {
      key: "createdAt",
      sortable: true,
      label: this.translateTableLabel("createdAt")
    },
    {
      key: "lastSeen",
      sortable: true,
      label: this.translateTableLabel("lastSeen")
    },
    {
      key: "projects",
      sortable: false,
      label: this.translateTableLabel("projects")
    },
    {
      key: "actions",
      sortable: true,
      label: this.translateTableLabel("actions")
    }
  ];
  actionStateSelected: null | object = null;
  deviceStatus: null | ActionMessage = null;
  editDevice: object | null = null;
  perPage: number = 50;
  currentPage: number = 1;
  filter: any = null;
  stateToFilter: string = "";
  filterOn: Array<object> = [];
  totalRows: number = 1;

  selected: Array<Device> = [];
  tableIsBusy: boolean = false;
  customFieldOptions: Array<string> = ["state"];
  allSelected = false;

  loading = false;

  filterIsOpen = false;

  TypeFilter: object | null = null;

  created() {
    this.loading = true;
    this.getAllDevicesFromBackEnd().then(() => {
      this.loading = false;
    });
  }

  private get rows() {
    return this.getAllDevices.length;
  }

  private get sortOptions() {
    // Create an options list from our fields
    return this.fields
      .filter((f: any) => f.sortable)
      .map((f: any) => {
        return { text: f.label, value: f.key };
      });
  }

  newDeviceStatus(modalName: string, value: RespStatus | false) {
    if (value) {
      this.$bvModal.hide(modalName);
      this.deviceStatus = value;
    }
  }

  openModalAndResetStatus(modalName: string, update: boolean = false, device: object) {

    this.deviceStatus = null;
    if (update) {
      this.disableButton('serialnumber');

      if (i18n.locale == "de") {
        let parseObj = JSON.parse(JSON.stringify(device));

        parseObj.type.name = this.$t(`inventory.createNewDevice.device_types.${parseObj.type.name}`);
        device = parseObj;
      }

      this.editDevice = device;
      this.$bvModal.show(modalName);
    } else {
      this.enableButton('serialnumber');

      this.editDevice = null;
      this.$bvModal.show(modalName);
    }
  }

  confirmModal(msg: string, action: Function, resetAction?: Function) {
    this.$bvModal
      .msgBoxConfirm(msg, {
        title: (this as any).$t("inventory.deviceActions.archiveDevice.modalTitle"),
        size: "md",
        buttonSize: "md",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0 bg-light",
        footerClass: "p-2 border-top-0",
        centered: true,
        okTitle: (this as any).$t("utils.alertBox.ok"),
        cancelVariant: "light",
        cancelTitle: (this as any).$t("utils.alertBox.cancel")
      })
      .then((value: boolean) => {
        if (value) {
          action();
        } else {
          if (resetAction) {
            resetAction();
          }
        }
      });
  }

  updateDeviceList() {
    this.getAllDevicesFromBackEnd();
  }

  onRowSelected(items: Array<Device>) {
    this.selected = items;
  }

  selectAllRows() {
    if (this.$mq !== "xl") {
      this.allSelected = true;
      // this.selected = this.getAllDevices;
    } else {
      //@ts-ignore
      this.$refs.desktopTable.$refs.inventoryTable.selectAllRows();
    }
  }

  async clearSelected() {
    this.actionStateSelected = null;
    this.selected = [];
    this.TypeFilter = null;
    if (this.$mq !== "xl") {
      this.tableIsBusy = true;
      this.allSelected = false;
      this.filter = null;
      await this.getAllDevicesFromBackEnd();
      this.tableIsBusy = false;
    } else {
      //@ts-ignore
      this.$refs.desktopTable.$refs.inventoryTable.clearSelected();
    }
  }

  handleFilterAction(state: string) {
    if (!state) return;
    this.confirmModal(
      (this as any).$t("inventory.deviceActions.deviceStatusChange.modalText", {
        status: state
      }),
      async () => {
        this.tableIsBusy = true;
        const allPromises = this.selected.map(async (device: Device) => {
          if (device.state === state) return Promise.resolve();
          device.state = state;
          return await this.updateDevice(device);
        });
        this.clearSelected();
        Promise.all(allPromises).then(() => {
          this.tableIsBusy = false;
          this.filter = "";
          this.deviceStatus = {
            class: "success",
            msg: this.$t("inventory.msgStatus.statusChangeSuccess", {
              newStatus: state
            }).toString()
          };
        });
      },
      () => {
        this.actionStateSelected = null;
      }
    );
  }

  filterActionHandler(filterAction: string) {
    this.filter = filterAction;
  }

  handleFilterOn(filterOnOptions: Array<object>) {
    this.filterOn = filterOnOptions;
  }

  resetFilter() {
    this.filter = "";
    this.stateToFilter = "";
  }

  translateTableLabel(label: string) {
    return this.$t(`inventory.tableLabels.${label}`);
  }

  updateStatus(status: any) {
    this.deviceStatus = status;
  }

  filterOpen(status: boolean) {
    this.filterIsOpen = status;
  }

  handleTypeChange(type: object | null) {
    this.TypeFilter = type;
  }

  handleDeviceArchive(id: string) {
    this.tableIsBusy = true;
    this.archiveDevice(id).then((res: any) => {
      if (res.status === 202) {
        this.updateStatus({
          class: "success",
          msg: this.$t("inventory.msgStatus.archiveSuccess")
        });
        this.tableIsBusy = false;
      } else {
        this.tableIsBusy = false;
        this.updateStatus({
          class: "danger",
          msg: res
        });
      }
    });
  }

  handleRebootRouter(serialNumber: string) {
    this.tableIsBusy = true;
    this.rebootRouter(serialNumber).then((res: any) => {
      if (res.status === 202) {
        this.updateStatus({
          class: "success",
          msg: this.$t("inventory.msgStatus.archiveSuccess")
        });
        this.tableIsBusy = false;
      } else {
        this.tableIsBusy = false;
        this.updateStatus({
          class: "danger",
          msg: res
        });
      }
    });
  }

  disableButton(inputId: string) {
    const inputField = document.getElementById(inputId);
    if (inputField) {
      inputField.setAttribute('disabled', 'disabled');
    }
  }

  enableButton(inputId: string) {
    const inputField = document.getElementById(inputId);
    if (inputField) {
      inputField.removeAttribute('disabled');
    }
  }



}
